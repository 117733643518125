import * as React from 'react';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TaskIcon from '@material-ui/icons/List'
import { useQueryWithStore, Record } from 'react-admin';
import { Link } from 'react-router-dom';
import CardWithIcon from './CardWithIcon';

const TaskInState = ({inState, label}:{inState:string, label:string}) => {
    const classes = useStyles();


    const { loaded, data: tasks } = useQueryWithStore({
        type: 'getList',
        resource: 'tasks',
        payload: {
            filter: {
                state: inState,
            },
            sort: { field: 'creation_date', order: 'DESC' },
            pagination: { page: 1, perPage: 30 },
        },
    });

    if (!loaded) return null;
    const nb = tasks ? tasks.reduce((nb: number) => ++nb, 0) : 0;

    return (
        <CardWithIcon
            to={`/tasks?filter={"state":"${inState}"}`}
            icon={TaskIcon}
            title={label}
            subtitle={nb}
        >
            <List>
                {tasks
                    ? tasks.map((record: Record) => (
                          <ListItem
                              button
                              to={`/tasks/${record.id}`}
                              component={Link}
                              key={record.id}
                          >
                              <ListItemText
                                  primary={record.summary}
                              />
                          </ListItem>
                      ))
                    : null}
            </List>
            <Box flexGrow="1">&nbsp;</Box>
            <Button
                className={classes.link}
                component={Link}
                to="/tasks"
                size="small"
                color="primary"
            >
                <Box p={1} className={classes.linkContent}>
                    {"See all Tasks"}
                </Box>
            </Button>
        </CardWithIcon>
    );
};

const useStyles = makeStyles(theme => ({
    link: {
        borderRadius: 0,
    },
    linkContent: {
        color: theme.palette.primary.main,
    },
}));

export default TaskInState;