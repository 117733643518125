import ClientIcon from '@material-ui/icons/PermContactCalendar';

import ClientList from './ClientList';
import ClientEdit from './ClientEdit';
import ClientCreate from './ClientCreate';
import ClientShow from './ClientShow';

const client_ressource = {
    list: ClientList,
    show: ClientShow,
    edit: ClientEdit,
    create: ClientCreate,
    icon: ClientIcon,
};

export default client_ressource;