import * as React from "react";
import { Fragment } from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    RichTextField,
    ReferenceField,
    BooleanField,
    ResourceContextProvider,
    List,
    Datagrid,
    NumberField,
    Toolbar,
} from 'react-admin';
import {Typography, Button, Box} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { TotalTimeOfTaskField, TotalCostOfTaskField } from "../timelogs/TotalTimeOfTaskField"
import CreateInvoiceButton from "../invoice/createInvoiceButton"

const CreateRelatedTaskButton = ({ record }) => (
    <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        component={Link}
        to={{
            pathname: '/tasks/create',
            state: { record: { project: record.id } },
        }}
    >
        Add Task for this Project
    </Button>
);

const InvoiceTasksBulkActionButtons = ({...props}) => (
    <Fragment>
        <CreateInvoiceButton {...props} />
    </Fragment>
);

const VerticalSpacer = () => <span style={{ height: '2em', display:'inline-block' }} />;
const VerticalSpacerfix = () => <span style={{ height: '100px' }} />;

const ProjectShow = ({ ...props }) => (
    <>
        <Show
            {...props}>
            <SimpleShowLayout >
                <NumberField source="id" />
                <TextField source="name" />
                <ReferenceField label="Client" source="client" reference="clients" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                <BooleanField source="active" />
                <RichTextField source="description" />
                <Toolbar>
                    <CreateRelatedTaskButton record={props.record} />
                </Toolbar>
            </SimpleShowLayout>
        </Show>
        <ResourceContextProvider value="tasks">
            <VerticalSpacer />
            <Typography variant="h6">Invoice</Typography>
            <List 
                {...props}
                bulkActionButtons={<InvoiceTasksBulkActionButtons project_id={Number(props.id)}/>}
                hasList
                exporter={false}
                hasCreate={false}
                pagination={false}
                basePath="/tasks"
                resource="tasks"
                filter={
                    {
                        'project,eq': [props.id],
                        'state,cs': "Ready",
                    }
                }
                title=" " 
                empty={<Box textAlign="center" m={1}>
                            <Typography variant="subtitle1">Invoice can only be created if at least one task is in state "Ready"!</Typography>
                            <VerticalSpacer />
                            <Button variant="contained" color="primary" href={"#tasks?filter={project%3A"+ props.id + "}"} >Go to Tasks</Button>
                        </Box>}
            >
                <Datagrid >
                    <TextField label="Task" source="summary" />
                    <TotalTimeOfTaskField label="Spent Time" record={props.record} />
                    <TotalCostOfTaskField label="Price" record={props.record} />
                </Datagrid>
            </List>
        </ResourceContextProvider>
        <VerticalSpacerfix/>
    </>
);

export default ProjectShow;