import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    BooleanField,
    EditButton,
    SearchInput,
    Filter,
    SimpleList
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ ...props }: { label: string, source: string, defaultValue: any }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={(props.label)} />;
};

const ProjectFilter = ({ ...props }) => (
    <Filter {...props}>
        <SearchInput source="name" alwaysOn />
        <QuickFilter label="Active" source="active" defaultValue={1} />
        <ReferenceInput
            label="Client"
            source="client"
            reference="clients"
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty={true}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const ProjectList = ({ ...props }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            filters={<ProjectFilter />}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record =>
                        (
                            <ReferenceField basePath="clients" record={record} label="Client" source="client" reference="clients" link={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        )}
                    tertiaryText={record => record.active ? "active" : "inactive"}
                    linkType="show"
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <ReferenceField label="Client" source="client" reference="clients" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <BooleanField source="active" />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    )
};

export default ProjectList;