import TaskIcon from '@material-ui/icons/List';
import TaskCreate from './TaskCreate';
import TaskEdit from './TaskEdit';
import TaskList from './TaskList';


const task_ressource = {
    list: TaskList,
    edit: TaskEdit,
    create: TaskCreate,
    icon: TaskIcon,
};

export default task_ressource;