import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    NumberField,
    DateField,
    ChipField,
    Pagination,
    SimpleList,
    FilterLiveSearch,
    SelectInput,
    Filter,
    ReferenceInput,
} from 'react-admin';
import TaskFilterAside from './TaskFilterAside';
import { TotalTimeOfTaskField } from "../timelogs/TotalTimeOfTaskField";
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import ClientNameField from '../clients/ClientNameField';

const TaskPagination = ({ ...props }) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const TaskListFilter = ({ ...props }) => (
    <Filter {...props}>
        <FilterLiveSearch source="summary" />
        <SelectInput source="state" choices={[
            { id: 'ToDo', name: 'ToDo' },
            { id: 'InProgress', name: 'InProgress' },
            { id: 'OnHold', name: 'OnHold' },
            { id: 'Ready', name: 'Ready' },
            { id: 'Closed', name: 'Closed' },
        ]} />
        <ReferenceInput source="project,eq" label="Projects" reference="projects">
            <SelectInput optionText={record=>record.name + " - " + record.active} />
        </ReferenceInput >
    </Filter>
);

const TaskList = ({ ...props }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            aside={<TaskFilterAside />}
            pagination={<TaskPagination />}
            perPage={25}
            sort={{ field: 'creation_date', order: 'DESC' }}
            filters={isSmall ? <TaskListFilter /> : undefined}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.summary}
                    secondaryText={record =>
                    (
                        <ReferenceField basePath="projects" record={record} label="Project" source="project" reference="projects" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                    )}
                    tertiaryText={record => record.state}
                />
            ) : (
                <Datagrid rowClick="edit">
                    <TextField source="id" />
                    <ReferenceField label="Project" source="project" reference="projects" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Client" source="project" reference="projects" link={false}>
                        <ClientNameField />
                    </ReferenceField>
                    <TextField source="summary" />
                    <ReferenceField source="user" reference="users" link={false}>
                        <TextField source="username" />
                    </ReferenceField>
                    <DateField source="due_date" />
                    <NumberField source="priority" />
                    <ChipField source="state" />
                    <TotalTimeOfTaskField label="spent time" record={props.record} />
                </Datagrid>
            )}
        </List>
            );
};

export default TaskList;