import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    EditButton,
    Filter,
    SearchInput,
    SimpleList,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const ClientFilter = ({ ...props }) => (
    <Filter {...props}>
        <SearchInput source="name" alwaysOn />
    </Filter>
);

const ClientList = ({ ...props }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            filters={<ClientFilter />}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.mail}
                    tertiaryText={record => record.rate}
                    linkType="show"
                />
            ) : (
                <Datagrid rowClick="show">
                    <NumberField source="id" />
                    <TextField source="name" />
                    <TextField source="mail" />
                    <NumberField source="rate" />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    )
};

export default ClientList;