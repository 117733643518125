import ProjectIcon from '@material-ui/icons/Work';
import ProjectCreate from './ProjectCreate';
import ProjectEdit from './ProjectEdit';
import ProjectList from './ProjectList';
import ProjectShow from './ProjectShow';


const project_ressource = {
    list: ProjectList,
    show: ProjectShow,
    edit: ProjectEdit,
    create: ProjectCreate,
    icon: ProjectIcon,
};

export default project_ressource;