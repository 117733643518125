import * as React from 'react';
import { FC } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterProjectIcon from '@material-ui/icons/Work';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import {
    FilterLiveSearch,
    FilterList,
    FilterListItem,
    useQueryWithStore,
} from 'react-admin';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const Aside: FC = () => {
    const { loaded: project_active_loaded, data: project_data_active } = useQueryWithStore({
        type: 'getList',
        resource: 'projects?join=clients',
        payload: { filter:{active:1}  },
    }); 
    const { loaded: project_inactive_loaded, data: project_data_inactive } = useQueryWithStore({
        type: 'getList',
        resource: 'projects?join=clients',
        payload: { filter:{active:0}  },
    }); 
    
    if (!project_active_loaded || !project_inactive_loaded) return null;
    return (
        <Card>
            <CardContent>
                <FilterLiveSearch source="summary" />
                <FilterList
                    label="Projects-Active"
                    icon={<FilterProjectIcon />}
                >
                    {   project_data_active.map((data: any) => (
                            <FilterListItem
                                label={(data.name) + ' - [' + (data.client.name) + ']'}
                                key={data.id}
                                value={{ "project,eq": data.id }}
                            />
                        ))}
                </FilterList>
                <FilterList
                    label="Projects-Inactive"
                    icon={<FilterProjectIcon />}
                >
                    {   project_data_inactive.map((data: any) => (
                            <FilterListItem
                                label={(data.name) + ' - [' + (data.client.name) + ']'}
                                key={data.id}
                                value={{ "project,eq": data.id }}
                            />
                        ))}
                </FilterList>

                <FilterList
                    label="is in state"
                    icon={<FilterListIcon />}
                >
                    <FilterListItem
                        label="ToDo"
                        value={{ state: "ToDo" }}
                    />
                    <FilterListItem
                        label="InProgress"
                        value={{ state: "InProgress" }}
                    />
                    <FilterListItem
                        label="OnHold"
                        value={{ state: "OnHold" }}
                    />
                    <FilterListItem
                        label="Ready"
                        value={{ state: "Ready" }}
                    />
                    <FilterListItem
                        label="Closed"
                        value={{ state: "Closed" }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;