import * as React from "react";
import {
    Edit,
    FormWithRedirect,
    TextInput,
    ReferenceInput,
    DateInput,
    SelectInput,
    NumberInput,
    SaveButton,
    DeleteButton,
    CloneButton,
    SimpleList,
    ReferenceManyField,
    required,
    maxLength,
    Labeled,
    Toolbar,
    FormDataConsumer
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Typography, Box, useMediaQuery, Divider } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import TimeLogCreateButton from "../timelogs/TimeLogCreateDialogButton";
import { TotalTimeOfTaskField } from "../timelogs/TotalTimeOfTaskField";


const TaskEditForm = ({ ...props }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <FormWithRedirect
            {...props}
            render={formProps => (
                // here starts the custom form layout
                <form>
                    {isSmall ? (
                        <Box p="1em">
                            <Typography variant="h6" gutterBottom>Basic</Typography>
                            <TextInput source="summary" fullWidth validate={[required(), maxLength(250)]} />
                            <Box >
                                <ReferenceInput source="project" reference="projects">
                                    <SelectInput source="name" validate={required()} />
                                </ReferenceInput >
                            </Box>
                            <Box >
                                <ReferenceInput source="user" reference="users">
                                    <SelectInput source="username" optionText="username" validate={required()} />
                                </ReferenceInput >
                            </Box>
                            <Box >
                                <NumberInput source="priority" defaultValue={0} />
                            </Box>
                            <Box>
                                <SelectInput source="state" choices={[
                                    { id: 'ToDo', name: 'ToDo' },
                                    { id: 'InProgress', name: 'InProgress' },
                                    { id: 'OnHold', name: 'OnHold' },
                                    { id: 'Ready', name: 'Ready' },
                                    { id: 'Closed', name: 'Closed' },
                                ]} defaultValue={"ToDo"} />
                            </Box>
                            <Typography variant="h6" gutterBottom>Description</Typography>
                            <RichTextInput source="description" />
                            <Typography variant="h6" gutterBottom>Dates and Time</Typography>
                            <DateInput source="due_date" fullWidth />
                            <TextInput source="eastimated_time" fullWidth type="time" />
                            <Typography variant="h6" gutterBottom>TimeLogs</Typography>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.state !== "Closed" &&
                                    <TimeLogCreateButton task_id={props.record.id} />
                                }
                            </FormDataConsumer>
                            <ReferenceManyField label="Timelog" reference="timelogs" target="task" sort={{ field: "date", order: "DESC" }} basePath="/tasks" record={props.record}>
                                <SimpleList
                                    primaryText={record => record.time}
                                    secondaryText={record => record.comment}
                                    tertiaryText={record => new Date(record.date).toLocaleDateString()}
                                    linkType={"edit"}
                                />
                            </ReferenceManyField>
                            <Divider />
                            <Labeled label="total time">
                                <TotalTimeOfTaskField label="total time" record={props.record} />
                            </Labeled>
                        </Box>
                    ) : (
                        <Box p="1em">
                            <Box display="flex">
                                <Box flex={3} mr="1em">
                                    <Typography variant="h6" gutterBottom>Basic</Typography>
                                    <TextInput source="summary" validate={[required(), maxLength(250)]} fullWidth />
                                    <Box display="flex">
                                        <Box flex={1} mr="0.5em">
                                            <ReferenceInput source="project" reference="projects">
                                                <SelectInput source="name" validate={required()} />
                                            </ReferenceInput >
                                        </Box>
                                        <Box flex={1} ml="0.5em">
                                            <ReferenceInput source="user" reference="users">
                                                <SelectInput source="username" optionText="username" validate={required()} />
                                            </ReferenceInput >
                                        </Box>
                                        <Box flex={1} flexGrow="2"></Box>
                                    </Box>
                                    <Box display="flex">
                                        <Box flex={1} mr="0.5em">
                                            <NumberInput source="priority" />
                                        </Box>
                                        <Box flex={1} ml="0.5em">
                                            <SelectInput source="state" choices={[
                                                { id: 'ToDo', name: 'ToDo' },
                                                { id: 'InProgress', name: 'InProgress' },
                                                { id: 'OnHold', name: 'OnHold' },
                                                { id: 'Ready', name: 'Ready' },
                                                { id: 'Closed', name: 'Closed' },
                                            ]} />
                                        </Box>
                                        <Box flex={1} flexGrow="2"></Box>
                                    </Box>
                                    <Box mt="1em" />
                                    <Typography variant="h6" gutterBottom>Description</Typography>
                                    <RichTextInput source="description" />
                                </Box>

                                <Box flex={1} ml="1em">

                                    <Typography variant="h6" gutterBottom>Dates and Time</Typography>
                                    <DateInput source="creation_date" disabled fullWidth />
                                    <DateInput source="due_date" fullWidth />
                                    <TextInput source="eastimated_time" fullWidth type="time" />
                                    <Typography variant="h6" gutterBottom>TimeLogs</Typography>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.state !== "Closed" &&
                                            <TimeLogCreateButton task_id={props.record.id} />
                                        }
                                    </FormDataConsumer>

                                    <ReferenceManyField label="Timelog" reference="timelogs" target="task" sort={{ field: "date", order: "DESC" }} basePath="/tasks" record={props.record}>
                                        <SimpleList
                                            primaryText={record => record.time}
                                            secondaryText={record => record.comment}
                                            tertiaryText={record => new Date(record.date).toLocaleDateString()}
                                            linkType={"edit"}
                                        />
                                    </ReferenceManyField>
                                    <Divider />
                                    <Labeled label="total time">
                                        <TotalTimeOfTaskField label="total time" record={props.record} />
                                    </Labeled>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <Toolbar >
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <SaveButton saving={props.saving} handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} />
                            <CloneButton basePath="/tasks" record={props.record} />
                            <DeleteButton basePath="/tasks" record={props.record} />
                        </Box>
                    </Toolbar>
                </form>
            )}
        />
    );
};

const TaskEdit = ({ ...props }) => (
    <Edit
        {...props}>
        <TaskEditForm redirect="list" />
    </Edit>
);

export default TaskEdit;