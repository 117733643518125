import * as React from "react";
import { 
    Edit, 
    SimpleForm, 
    TextInput,
    NumberField, 
    NumberInput, 
    required,
    maxLength,
    email,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const ClientEdit = ({...props}) => (
    <Edit 
    {...props}>
        <SimpleForm >
            <NumberField source="id" />
            <TextInput source="name" validate={[required(),maxLength(250)]}/>
            <TextInput source="mail" validate={email()} />
            <NumberInput source="rate" defaultValue={37.5} />
            <RichTextInput source="note" />
        </SimpleForm>
    </Edit>
);

export default ClientEdit;