import * as React from 'react';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    useQuery,
} from 'react-admin';
import {getTotalLoggedTime} from '../timelogs/TotalTimeOfTaskField'
import InvoiceDocument from './createInvoicePdf'
import { PDFDownloadLink} from '@react-pdf/renderer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import PaymentIcon from '@material-ui/icons/Payment';

const CreateInvoiceButton =  ({ resource, selectedIds, project_id }) => {
    const { loaded:project_loaded, data: project_data } = useQuery({
        type: 'getList',
        resource: 'projects?join=clients',
        payload: {
            filter: { 'id,eq': project_id
            }
        },
    });
    const { loaded:tasks_loaded, data: task_data } = useQuery({
        type: 'getList',
        resource: 'tasks?join=timelogs',
        payload: {
            filter: { 'id,in': selectedIds
            }
        },
    });


    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const createInvoice = () =>{
        let invoice_client:any = {};
        let invoice_tasks:any[] = [];
        let invoice_total:number = 0;
        if (!project_loaded || !tasks_loaded ) return null;
        let project = project_data[0]
        const rate:number =  Number(project.client.rate)
        invoice_client = {
            id:   project.client.id,
            name: project.client.name, 
            mail: project.client.mail,
            rate: rate,
        }
        let total_price = 0;
        task_data.forEach(element => {
            invoice_tasks.push(
                {
                    name: element.summary,
                    time: getTotalLoggedTime(element.timelogs),
                    price: ((getTotalLoggedTime(element.timelogs) * rate) / 3600)
                }
            )
            total_price += ((getTotalLoggedTime(element.timelogs)* rate)/3600)
        });
        invoice_total = total_price;
        return (
            <InvoiceDocument id={project.id} client={invoice_client} tasks={invoice_tasks} total_price={invoice_total} />
        )
    }
    const [updateMany] = useUpdateMany(
        'tasks',
        selectedIds,
        { state: "Closed" },
        {
            onSuccess: () => {
                refresh();
                notify('Tasks updated');
                unselectAll('tasks');
            },
            onFailure: error => notify('Error: Tasks not updated', 'warning'),
        }
    );
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button variant="contained" label="Create Invoice" icon={<PaymentIcon />} onClick={handleClick}  >
                <PaymentIcon />
            </Button>
            <Dialog
                fullWidth
                open={open}
                onClose={handleDialogClose}
                aria-label="Create Invoice"
            >
                <DialogTitle>Create Invoice</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Please download the Invoice and hit submit to confirm the tickets</Typography>
                </DialogContent>
                <DialogActions>
                    <PDFDownloadLink document={createInvoice()} fileName="invoice.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Download Invoice'
                        }
                    </PDFDownloadLink>
                    <Button
                        label="ra.action.confirm"
                        onClick={handleConfirm}
                    >
                        <DoneIcon/>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

CreateInvoiceButton.propTypes = {
    basePath: PropTypes.string,
    label: PropTypes.string,
    resource: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    project_id: PropTypes.number,
};

export default CreateInvoiceButton;