import * as React from "react";
import { 
    Create, 
    SimpleForm, 
    TextInput, 
    NumberInput, 
    required,
    maxLength,
    email,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const ClientCreate = ({...props}) => (
    <Create 
    {...props}>
        <SimpleForm >
            <TextInput source="name" validate={[required(),maxLength(250)]}/>
            <TextInput source="mail" validate={email()} />
            <NumberInput source="rate" defaultValue={37.5} />
            <RichTextInput source="note" />
        </SimpleForm>
    </Create>
);

export default ClientCreate;