import * as React from "react";
import { 
    Create, 
    SimpleForm, 
    TextInput, 
    ReferenceInput, 
    BooleanInput, 
    SelectInput,
    required,
    maxLength,  
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const ProjectCreate = ({...props}) => (
    <Create 
    {...props}>
        <SimpleForm >
            <TextInput source="name" validate={[required(), maxLength(250)]}/>
            <ReferenceInput  source="client" reference="clients">
                <SelectInput  source="name" validate={required()}/>
            </ReferenceInput >
            <BooleanInput source="active" defaultValue={true} />
            <RichTextInput source="description" />
        </SimpleForm>
    </Create>
);

export default ProjectCreate;