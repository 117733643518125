import * as React from "react";
import { useNotify, useRefresh, useRedirect,Edit, SimpleForm, TextInput, DateInput, Record, ReferenceInput, SelectInput} from 'react-admin';

const TimeLogEdit = ({...props}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    
    const onSuccess = (record:Record) => {
        notify(`Changes saved`)
        redirect('/tasks/'+record.data.task);
        refresh();
    };

    return(
    <Edit onSuccess={onSuccess} mutationMode="pessimistic" {...props}>
        <SimpleForm >
            <ReferenceInput source="task" reference="tasks">
                <SelectInput source="id" optionText="summary" />
            </ReferenceInput >
            <DateInput source="date" />
            <TextInput source="time" />
            <TextInput source="comment" />
        </SimpleForm>
    </Edit>
)};

export default TimeLogEdit;