import * as React from "react";
import {
    Record,
    useQueryWithStore,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';



export const getTotalLoggedTime = (logs: Record[]):number => {
    return logs.reduce((acc: number, curr) => {
        let [hours, minutes, seconds] = curr.time.split(':');
        acc += Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
        return acc;
    }, 0);
}

export const TotalTimeOfTaskField = ({ record }: { record: Record }) => {
    const { loaded, data: logs } = useQueryWithStore({
        type: 'getList',
        resource: 'timelogs',
        payload: {
            filter: { 'task,eq': record.id }
        },
    });
    if (!loaded) return null;
    const total_time = getTotalLoggedTime(logs);
    return (
        <Typography
            variant="body1"
            component="span"
            className="total_spent_time"
            >
            {new Date(total_time * 1000).toISOString().substr(11, 8)}
        </Typography>
    )
};

TotalTimeOfTaskField.propTypes = {
    label: PropTypes.string,
    task_id: PropTypes.string,
};

export const TotalCostOfTaskField = ({ record }: { record: Record }) => {
    const { loaded:time_loaded, data: logs } = useQueryWithStore({
        type: 'getList',
        resource: 'timelogs?join=tasks,projects,clients',
        payload: {
            filter: { 'task,eq': record.id }
        },
    });
    if (!time_loaded ) return null;
    const rate:number = Number(logs[0] ? logs[0].task.project.client.rate : 0);
    const total_cost = (getTotalLoggedTime(logs) * rate)/3600;
    return (
        <Typography
            variant="body1"
            component="span"
            className="total_cost"
            >
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(total_cost)}
        </Typography>
    )
};

TotalCostOfTaskField.propTypes = {
    label: PropTypes.string,
    task_id: PropTypes.string,
    project_id: PropTypes.string,
};


