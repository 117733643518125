import * as React from "react";
import { Admin, Resource } from 'react-admin';
import basicAuthProvider from './authProvider';
import treeqlProvider from './dataProvider';
import Dashboard from './dashboard';
import clients from './clients'
import projects from "./projects";
import tasks from "./tasks";
import TimeLogEdit from "./timelogs/TimeLogEdit"

const dataProvider = treeqlProvider('/api.php');
const authProvider = basicAuthProvider();
const App = () => (
    <Admin authProvider={authProvider} dashboard={Dashboard} dataProvider={dataProvider}>
        <Resource name="clients" {...clients} />
        <Resource name="projects" {...projects}/>
        <Resource name="tasks" {...tasks} />
        <Resource name="timelogs" edit={TimeLogEdit}/>
        <Resource name="users"/>
    </Admin>
);

export default App;