import { AuthProvider, UserIdentity } from 'ra-core';

const authProvider = (): AuthProvider => ({
    login: (params) => {
        if (params.username && params.password) {
            const request = new Request('api.php/login', {
                method: 'POST',
                body: JSON.stringify({ ...params }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(auth => {
                    localStorage.setItem('authenticated', "true");
                    localStorage.setItem('role', "default");
                    localStorage.setItem('user', auth.username);
                    localStorage.setItem('user_id', auth.id);
                    return Promise.resolve();
                })
                .catch((error) => {
                    throw new Error(error)
                });
        }
        localStorage.setItem('authenticated', "false");
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        localStorage.removeItem('user_id');
        return Promise.reject();
    },
    logout: (params) => {
        const request = new Request('api.php/logout', {
            method: 'POST',
            body: "",
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('authenticated', "false");
                localStorage.removeItem('role');
                localStorage.removeItem('user');
                localStorage.removeItem('user_id');
                return Promise.resolve();
            })
            .catch(() => {
                localStorage.setItem('authenticated', "false");
                localStorage.removeItem('role');
                localStorage.removeItem('user');
                localStorage.removeItem('user_id');
                return Promise.resolve();
            });
    },
    checkError: (error) => {
        if(error.status === 401 || error.status === 403)
        {
            return Promise.reject();
        }
        else
        {
            return Promise.resolve();
        }
    },
    checkAuth: () => {
        if (localStorage.getItem('authenticated') === "true")
        {
            return Promise.resolve();
        }
        else {
            return Promise.reject();
        }
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return Promise.resolve(role);
    },

    getIdentity: () => {
        const user_id = String(localStorage.getItem('user_id'));
        const username = localStorage.getItem('user');
        const identity: UserIdentity = { id: user_id, fullName: username, avatar: "" };
        return Promise.resolve(identity);
    },

});

export default authProvider;