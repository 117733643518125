import * as React from 'react';
import {
    useRecordContext,
    useQueryWithStore,
} from 'react-admin'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types';

const ClientNameField = (props) => {
    const record = useRecordContext(props);
    const { loaded, data } = useQueryWithStore({
        type: 'getOne',
        resource: 'clients',
        payload: { "id": record.client },
    });
    if (!loaded) return null;
    return (
        <Typography variant="body2" component="span"> {data.name}</Typography>
    )
}

ClientNameField.propTypes = {
    record: PropTypes.object,
};

export default ClientNameField