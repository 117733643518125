import * as React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';
import { format, subDays, addDays } from 'date-fns';
import { useQueryWithStore, Record } from 'react-admin'


const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date: number): string =>
    new Date(date).toLocaleDateString();

const aggregateTimeLogsByDay = (logs: Record[]): { [key: string]: number } =>
    logs
        .reduce((acc, curr) => {
            const day = format(curr.date, 'YYYY-MM-DD');
            if (!acc[day]) {
                acc[day] = 0;
            }
            let [hours, minutes, seconds] = curr.time.split(':');
            acc[day] += Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
            return acc;
        }, {} as { [key: string]: number });

const getSpentTimePerDay = (logs: Record[]): TotalByDay[] => {
    const daysWithSpentTime = aggregateTimeLogsByDay(logs);
    return lastMonthDays.map(date => ({
        date: date.getTime(),
        time: ((daysWithSpentTime[format(date, 'YYYY-MM-DD')] || 0)/3600),
    }));
};

const TimeLogChart = ({ label }: { label: string }) => {

    const { loaded, data: logs } = useQueryWithStore({
        type: 'getList',
        resource: 'timelogs',
        payload: {
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 300 },
        },
    });
    if (!loaded) return null;
    return (
        <Card>
            <CardHeader title={label} />
            <CardContent>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <BarChart data={getSpentTimePerDay(logs)}>
                            <XAxis
                                dataKey="date"
                                name="Date"
                                type="number"
                                scale="time"
                                domain={[
                                    addDays(aMonthAgo, 1).getTime(),
                                    new Date().getTime(),
                                ]}
                                tickFormatter={dateFormatter}
                            />
                            <YAxis name="time" />
                            <Bar dataKey="time" fill="#ffb900" />
                            <Tooltip
                                formatter={value =>
                                    new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value as any)
                                }
                                labelFormatter={(date: any) =>
                                    dateFormatter(date)
                                }
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

interface TotalByDay {
    date: number;
    time: number;
}

export default TimeLogChart;
