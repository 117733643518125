import React from 'react';
import PropTypes from 'prop-types';
import Invoice from './reports/Invoice'


const InvoiceDocument = ({ id, client, tasks, total_price }) => (
    <Invoice invoice={{'id':id, 'balance':total_price, 'client':client, 'items': tasks }}></Invoice>
);

InvoiceDocument.propTypes = {
    id: PropTypes.number.isRequired,
    client: PropTypes.any.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.any).isRequired,
    total_price: PropTypes.number.isRequired,
};

export default InvoiceDocument