import * as React from "react";
import { Card, CardContent, CardHeader, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import TasksInState from "./TasksInState";
import TimeLogChart from "./TimeLogChart";
import RichTextInput from 'ra-input-rich-text';
import {
    Edit,
    SimpleForm,
    useGetIdentity,
    Toolbar,
    SaveButton,
} from 'react-admin';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em', minWidth: '250px' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const NotesToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const Dashboard = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const { identity, loading: identityLoading } = useGetIdentity();
    if (identityLoading) return <>Loading...</>;
    return (
        <>
            <Card>
                <CardHeader title={"Welcome to the MiTime - " + identity.fullName} />
                <CardContent>
                    Don't Panic!
                </CardContent>
            </Card>
            <Edit resource="users" basePath="/" id={String(identity.id)}>
                <SimpleForm toolbar={<NotesToolbar/>}>
                    <RichTextInput source="notes"></RichTextInput>
                </SimpleForm>
            </Edit>
            <VerticalSpacer />
            {isSmall ? (<></>) : (
                <>
                    <div style={styles.flex}>
                        <div style={styles.singleCol}>
                            <TasksInState inState="ToDo" label="ToDo" ></TasksInState>
                        </div>
                        <Spacer />
                        <div style={styles.singleCol}>
                            <TasksInState inState="InProgress" label="InProgress" ></TasksInState>
                        </div>
                        <Spacer />
                        <div style={styles.singleCol}>
                            <TasksInState inState="OnHold" label="OnHold" ></TasksInState>
                        </div>
                    </div>
                    <VerticalSpacer />
                </>
            )}
            <TimeLogChart label="Spent Hours" ></TimeLogChart>
            <VerticalSpacer />
            <VerticalSpacer />
            <VerticalSpacer />
        </>
    );
};

export default Dashboard;