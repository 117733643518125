import * as React from "react";
import { 
    Edit, 
    SimpleForm, 
    TextInput, 
    ReferenceInput, 
    BooleanInput, 
    SelectInput,
    NumberField,
    required,
    maxLength,  
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const ProjectEdit = ({...props}) => (
    <Edit 
    {...props}>
        <SimpleForm >
            <NumberField source="id" />
            <TextInput source="name" validate={[required(), maxLength(250)]} />
            <ReferenceInput  source="client" reference="clients">
                <SelectInput  source="name" validate={required()} />
            </ReferenceInput >
            <BooleanInput source="active" />
            <RichTextInput source="description" />
        </SimpleForm>
    </Edit>
);

export default ProjectEdit;