import * as React from "react";
import {
    useGetIdentity,
    Create,
    FormWithRedirect,
    TextInput,
    ReferenceInput,
    DateInput,
    SelectInput,
    AutocompleteInput,
    NumberInput,
    SaveButton,
    required,
    maxLength,
    Toolbar,
} from 'react-admin';
import { Typography, Box, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';

const TaskCreateForm = ({ ...props }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const { identity, loading: identityLoading } = useGetIdentity();
    if (identityLoading) return <>Loading...</>;
    return (
        <FormWithRedirect
            {...props}
            redirect={'edit'}
            render={formProps => (
                // here starts the custom form layout
                <form>
                    {isSmall ? (
                        <Box p="1em">
                            <Typography variant="h6" gutterBottom>Basic</Typography>
                            <TextInput source="summary" fullWidth validate={[required(), maxLength(250)]} />
                            <Box >
                                <ReferenceInput source="project" reference="projects" filter={{ active: 1 }} perPage={500}>
                                    <AutocompleteInput source="name" validate={required()} />
                                </ReferenceInput >
                            </Box>
                            <Box >
                                <ReferenceInput source="user" reference="users" defaultValue={identity.id}>
                                    <SelectInput source="username" optionText="username" validate={required()} />
                                </ReferenceInput >
                            </Box>
                            <Box >
                                <NumberInput source="priority" defaultValue={0} />
                            </Box>
                            <Box>
                                <SelectInput source="state" choices={[
                                    { id: 'ToDo', name: 'ToDo' },
                                    { id: 'InProgress', name: 'InProgress' },
                                    { id: 'OnHold', name: 'OnHold' },
                                    { id: 'Ready', name: 'Ready' },
                                    { id: 'Closed', name: 'Closed' },
                                ]} defaultValue={"ToDo"} />
                            </Box>
                            <Typography variant="h6" gutterBottom>Description</Typography>
                            <RichTextInput source="description" />
                            <Typography variant="h6" gutterBottom>Dates and Time</Typography>
                            <DateInput source="due_date" fullWidth />
                            <TextInput source="eastimated_time" fullWidth type="time" />
                        </Box>
                    ) : (
                    <Box p="1em">
                        <Box display="flex">
                            <Box flex={3} mr="1em">
                                <Typography variant="h6" gutterBottom>Basic</Typography>
                                <TextInput source="summary" fullWidth validate={[required(), maxLength(250)]} />
                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <ReferenceInput source="project" reference="projects" filter={{ active: 1 }} perPage={500}>
                                            <AutocompleteInput source="name" validate={required()} />
                                        </ReferenceInput >
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <ReferenceInput source="user" reference="users" defaultValue={identity.id}>
                                            <SelectInput source="username" optionText="username" optionValue="id" validate={required()} />
                                        </ReferenceInput >
                                    </Box>
                                    <Box flex={1} flexGrow="2"></Box>
                                </Box>
                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <NumberInput source="priority" defaultValue={0}/>
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <SelectInput source="state" choices={[
                                            { id: 'ToDo', name: 'ToDo' },
                                            { id: 'InProgress', name: 'InProgress' },
                                            { id: 'OnHold', name: 'OnHold' },
                                            { id: 'Ready', name: 'Ready' },
                                            { id: 'Closed', name: 'Closed' },
                                        ]} defaultValue={"ToDo"} />
                                    </Box>
                                    <Box flex={1} flexGrow="2">
                                    </Box>
                                </Box>
                                <Box mt="1em" />
                                <Typography variant="h6" gutterBottom>Description</Typography>
                                <RichTextInput source="description" />
                            </Box>

                            <Box flex={1} ml="1em">

                                <Typography variant="h6" gutterBottom>Dates and Time</Typography>
                                <DateInput source="due_date" fullWidth />
                                <TextInput source="eastimated_time" fullWidth type="time"/>
                            </Box>

                        </Box>
                    </Box>
                    )}
                    <Toolbar>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <SaveButton label="Save" saving={props.saving} handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} />
                        </Box>
                    </Toolbar>
                </form>
            )}
        />
    )
};
const TaskCreate = ({ ...props }) => (
    <Create
        {...props}>
        <TaskCreateForm redirect="list" />
    </Create>
);

export default TaskCreate;