import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    RichTextField,
    Toolbar,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

const CreateRelatedProjectButton = ({ record }) => (
    <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        component={Link}
        to={{
            pathname: '/projects/create',
            state: { record: { client: record.id } },
        }}
    >
        Add Project for this client
    </Button>
);

const ClientShow = ({ ...props }) => (
    <Show
        {...props}>
        <SimpleShowLayout>
            <NumberField source="id" />
            <TextField source="name" />
            <TextField source="mail" />
            <NumberField source="rate" />
            <RichTextField source="note" />
            <Toolbar >
                <CreateRelatedProjectButton record={props.record} />
            </Toolbar>
        </SimpleShowLayout>
    </Show>
);

export default ClientShow;